import NxuSelect from "components/atom/nxu-select";
import { NxuSelectProps } from "components/atom/nxu-select/nxu-select";
import { useCountries } from "utils/hooks/admissions";

type Props = {} & Omit<NxuSelectProps, "options">;

export const CountrySelect = ({ ...props }: Props) => {
  const { data: countryList, isLoading } = useCountries();

  return <NxuSelect options={countryList?.Countries ?? []} isLoading={isLoading} {...props} />;
};
