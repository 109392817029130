import { useEffect, useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useValidateVerification,
  useEmailVerification,
  useEnrollmentCodeVerification,
} from "utils/hooks/authentication";
import { StorageKeys } from "constants/storage-keys";

import { NxuAlert, NxuPrimaryButton, NxuSecondaryButton } from "@nexford/nexford-ui-component-library";

import "./code-verification-form.scss";
import { usePartnerDetails } from "utils/hooks/partners";
import { ValidateVerificationCodeResponse } from "types/authentication";
import { EnrollmentCodeInput } from "./enrollment-code-input/enrollment-code-input";
import { VerificationCodeInput } from "./verification-code-input";
import { CodeVerificationFormValues, getCodeVerificationFormSchema } from "./schema";

export interface CodeVerificationFormProps {
  storedEmail: string;
  emailVerified: boolean;
  emailSubmitted: boolean;
  onVerifyPartner?: () => void;
  setCodeVerified: (isTrue: boolean) => void;
  setTokenResponse: (token: string) => void;
  parentDisabledEvent?: boolean;
  partnerId?: string;
  partnerAssociated?: boolean;
}

/**
 * Having submitted their email, applicant must verify the code they received
 */
const CodeVerificationForm = (props: CodeVerificationFormProps) => {
  const {
    storedEmail,
    emailVerified,
    emailSubmitted,
    setCodeVerified,
    setTokenResponse,
    parentDisabledEvent,
    partnerId,
    onVerifyPartner,
    partnerAssociated,
  } = props;

  const { data: partnerDetails } = usePartnerDetails(true, { partnerId });
  const hasPartner = Boolean(partnerDetails?.name);

  const isEnrollmentCodeFlow = hasPartner && emailVerified && !partnerAssociated;

  const newCodeVerificationForm = useForm({
    defaultValues: {
      Email: storedEmail || "",
      Code: "",
      enrollmentCode: "",
    },
    resolver: yupResolver(getCodeVerificationFormSchema(isEnrollmentCodeFlow, hasPartner && !partnerAssociated)),
  });

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isValid },
  } = newCodeVerificationForm;

  const sendVerificationEmail = useEmailVerification();

  const sendCodeToVerification = useValidateVerification(() => {
    setCodeVerified(true);
  });

  const sendEnrollmentCodeVerification = useEnrollmentCodeVerification(() => {
    onVerifyPartner?.();
  });

  const isVerificationComplete = hasPartner
    ? (sendCodeToVerification.isSuccess && sendEnrollmentCodeVerification.isSuccess) ||
      (emailVerified && partnerAssociated)
    : sendCodeToVerification.isSuccess || emailVerified;

  const handleCodeVerificationSubmit = async ({ Code, Email, enrollmentCode }: CodeVerificationFormValues) => {
    if (!isValid || sendCodeToVerification.isPending || sendEnrollmentCodeVerification.isPending) return;

    let codeVerificationResponse: ValidateVerificationCodeResponse | undefined;
    try {
      const codeVerified = sendCodeToVerification.isSuccess || emailVerified;
      if (!codeVerified) {
        codeVerificationResponse = await sendCodeToVerification.mutateAsync({
          Code: Code!.trim(),
          Email: Email!.trim(),
        });
      }

      if (hasPartner) {
        try {
          await sendEnrollmentCodeVerification.mutateAsync({
            partnerId,
            code: enrollmentCode!.trim(),
          });
        } catch (error) {
          setError("enrollmentCode", { message: "" });
        }
      }
    } catch (error) {
      setError("Code", { message: "" });
    } finally {
      // If the code verification was successful, store the token in session storage
      if (codeVerificationResponse) {
        sessionStorage.setItem(StorageKeys.APPLICATION_TOKEN, codeVerificationResponse.Link);
        setTokenResponse(codeVerificationResponse.Link);
      }

      setValue("Code", Code);
      setValue("enrollmentCode", enrollmentCode);
    }
  };

  const resendVerificationCode = async () => {
    // Function shouldn't be reachable without valid form values, but adding an escape just in case
    if (sendVerificationEmail.isPending) return;
    sendVerificationEmail.mutate({ Email: storedEmail });
  };

  const applyHotjarAttr = async (e: any) => {
    // Hotjar attr needs to be assigned to the inner input field of the IonInputs, as it doesn't apply recursively when applied directly to the IonInput tag
    // The form and input fields don't trigger any callbacks on mount, so we want to use the field's focus event as the trigger for setting the attr
    e.target.querySelector('input[aria-label="Verification code"]')?.setAttribute("data-hj-allow", "true");
  };

  useEffect(() => {
    if (sendVerificationEmail.isSuccess) {
      sendCodeToVerification.reset();
      sendVerificationEmail.reset();
    }
  }, [sendCodeToVerification, sendVerificationEmail]);

  const codeError = useMemo(() => {
    if (sendCodeToVerification.isError) {
      if (sendCodeToVerification.error.cause === 422 || sendCodeToVerification.error.cause === 400) {
        return "Invalid or expired Validation Code was submitted";
      }

      return `We were unable to process your Verification Code. Please refresh the page and try again`;
    }

    if (sendEnrollmentCodeVerification.isError) {
      if (sendEnrollmentCodeVerification.error.cause === 404) {
        return "Invalid or expired Enrollment Code was submitted";
      }

      if (sendEnrollmentCodeVerification.error.cause === 409) {
        return "Enrollment Code is already used";
      }

      return `We were unable to process your Enrollment Code. Please refresh the page and try again`;
    }

    return null;
  }, [sendCodeToVerification, sendEnrollmentCodeVerification]);

  const showResendBtn =
    sendCodeToVerification.isError && [422, 400].includes(sendCodeToVerification.error.cause as number);

  useEffect(() => {
    if (storedEmail) {
      setValue("Email", storedEmail);
    }
  }, [setValue, storedEmail]);

  if (isVerificationComplete) {
    return (
      <div className="code-verification__complete">
        <IonIcon icon={checkmarkCircleOutline} />
        <span>Your email address has been verified</span>
      </div>
    );
  }

  if (!emailSubmitted) return null;

  return (
    <FormProvider {...newCodeVerificationForm}>
      <form
        className="code-verification-form"
        onSubmit={handleSubmit(handleCodeVerificationSubmit)}
        data-testid="code-verification-form"
      >
        {!emailVerified && (
          <VerificationCodeInput
            control={control}
            name="Code"
            disabled={sendCodeToVerification.isPending || parentDisabledEvent}
            onIonFocus={(e) => applyHotjarAttr(e)}
          />
        )}

        <EnrollmentCodeInput
          disabled={sendCodeToVerification.isPending || parentDisabledEvent}
          partnerAssociated={partnerAssociated}
          partnerId={partnerId}
        />

        {codeError && <NxuAlert fullWidth message={codeError} />}

        {showResendBtn && (
          <NxuSecondaryButton
            disabled={sendVerificationEmail.isPending || parentDisabledEvent}
            onClick={resendVerificationCode}
          >
            Resend verification code
          </NxuSecondaryButton>
        )}

        <NxuPrimaryButton
          type="submit"
          expand="block"
          disabled={
            sendCodeToVerification.isPending ||
            sendEnrollmentCodeVerification.isPending ||
            (sendCodeToVerification.isSuccess && sendEnrollmentCodeVerification.isSuccess) ||
            parentDisabledEvent
          }
        >
          {sendCodeToVerification.isPending ? "Verification in progress" : "Verify"}
        </NxuPrimaryButton>
      </form>
    </FormProvider>
  );
};

export default CodeVerificationForm;
