import { Input } from "components/atom/input";
import { usePartnerDetails } from "utils/hooks/partners";
import { useFormContext } from "react-hook-form";
import cn from "classnames";
import styles from "./enrollment-code-input.module.scss";

type TitleProps = {
  name: string;
};

const Title = ({ name }: TitleProps) => (
  <h4 data-testid="enrollment-code-input-title" className={styles.title}>
    It looks like you're starting your application through our partner: {name}
  </h4>
);

type LogoProps = {
  logoUrl?: string;
  className?: string;
  name?: string;
};

const Logo = ({ logoUrl, name, className }: LogoProps) => {
  if (!logoUrl) return null;

  return (
    <img
      data-testid="enrollment-code-input-logo"
      className={cn(styles.logo, className)}
      src={logoUrl}
      alt={`${name} logo`}
    />
  );
};

type ContentProps = {
  logoUrl?: string;
  name?: string;
  disabled?: boolean;
};

const Content = ({ name, logoUrl, disabled }: ContentProps) => {
  const { control } = useFormContext();

  return (
    <div className={styles.content}>
      <p className={styles.description}>
        To begin your journey towards a world-class education, please enter your unique Enrollment Code below. This will
        connect your application and ensure you receive all the benefits of this partnership.
      </p>

      <div className={styles.field}>
        <Logo logoUrl={logoUrl} name={name} />
        <Input
          control={control}
          name="enrollmentCode"
          data-testid="enrollment-code-input"
          fill="outline"
          placeholder="Enrollment Code"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

type Props = { disabled?: boolean; partnerId?: string; partnerAssociated?: boolean };

export const EnrollmentCodeInput = ({ disabled, partnerId, partnerAssociated }: Props) => {
  const { data } = usePartnerDetails(true, { partnerId });

  if (!data?.name) {
    return null;
  }

  const logoUrl = data.logoUploadedFileName
    ? `/api/public-learner-files/partners/${partnerId}/${data?.logoUploadedFileName}`
    : undefined;

  return (
    <div className={styles.container}>
      <Title name={data.name} />
      {!partnerAssociated && <Content name={data.name} logoUrl={logoUrl} disabled={disabled} />}
      {partnerAssociated && <Logo className={styles.medium} logoUrl={logoUrl} name={data.name} />}
    </div>
  );
};
