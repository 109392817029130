import * as yup from "yup";

export const BACHELORS_DEGREE = "Bachelor's Degree";
export const MASTERS_DEGREE = "Master's Degree or Higher";
export const OTHER_DEGREE = "Other";

export const educationFormSchema = yup.object().shape({
  ProductCode: yup.string(),
  EducationLevel: yup.string().required("Your education level is a required field").max(40),
  EducationLevelOther: yup.string().when("EducationLevel", {
    is: (val: any) => val === OTHER_DEGREE,
    then: (schema) =>
      schema
        .required("Your education level is required")
        .max(250, "Your education level should not be more than 250 characters"),
  }),
  DegreeName: yup
    .string()
    .trim()
    .when("EducationLevel", {
      is: (val: any) => val === BACHELORS_DEGREE || val === MASTERS_DEGREE,
      then: (schema) =>
        schema.required("Your degree is required").max(250, "Your degree should not be more than 250 characters"),
    }),
  GraduationYear: yup
    .string()
    .trim()
    .when("EducationLevel", {
      is: (val: any) => val === BACHELORS_DEGREE || val === MASTERS_DEGREE,
      then: (schema) => schema.required("Your graduation year is required"),
    }),
  InstitutionName: yup.string().trim(),
  UnaccreditedInstitutionName: yup.string().trim(),
  IsNewInstitution: yup.boolean(),
  InstitutionCountry: yup.string().when("IsNewInstitution", {
    is: (val: boolean) => !!val,
    then: (schema) => schema.trim().required("Your institution country is a required field"),
    otherwise: (schema) => schema.optional(),
  }),
  InstitutionSelect: yup.string().when(["InstitutionName", "UnaccreditedInstitutionName"], {
    is: (val1: any, val2: any) => !val1 && !val2,
    then: (schema) => schema.required("Your institution name is a required field"),
  }),
  BusinessRelated: yup.string().when(["EducationLevel", "ProgramCode"], {
    is: (level: any, code: string) =>
      code !== "MSDT" && code !== "MSBA" && (level === "Bachelor's Degree" || level === "Master's Degree or Higher"),
    then: (schema) => schema.required("Please state whether your degree is business-related or not"),
  }),
  UnofficialTranscriptFileId: yup.string(),
  UnofficialTranscriptFileName: yup.string(),
});

export type EducationFormSchemaType = yup.InferType<typeof educationFormSchema>;
