import React, { useEffect, useRef, useState } from "react";
import { IonInput } from "@ionic/react";
import { Controller, FormProvider, SubmitHandler, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import NxuSelect from "components/atom/nxu-select";
import EducationTranscriptUpload from "components/molecule/education-transcript-upload";
import { FetchEducationProfileResponse } from "types/learner-profile";
import { SelectItem } from "types/forms";
import { UniversityInput } from "./university-input/university-input";
import { BACHELORS_DEGREE, educationFormSchema, EducationFormSchemaType, MASTERS_DEGREE, OTHER_DEGREE } from "./schema";

// Constants for the Education Level values
const IS_NEW_INSTITUTION = "is_new_institution";
const ASSOCIATE_DEGREE = "Associate Degree";

const EDUCATION_LEVEL_OPTIONS: SelectItem[] = [
  { label: "Associate Degree", value: ASSOCIATE_DEGREE },
  { label: "Bachelor's Degree", value: BACHELORS_DEGREE },
  { label: "Master's Degree or Higher", value: MASTERS_DEGREE },
  { label: "Other", value: OTHER_DEGREE },
];

const BUSINESS_RELATED_OPTIONS: SelectItem[] = [
  { label: "Yes, my degree is in a business-related field", value: "Yes" },
  { label: "No, my degree is not in a business-related field", value: "No" },
  { label: "I'm unsure if my degree was in a business-related field", value: "Unsure" },
];

export interface EducationFormProps {
  submitEventInProgress: boolean;
  submitEventLabel: string;
  submitEventInProgressLabel: string;
  parentFormHandler: SubmitHandler<EducationFormSchemaType>;
  existingApplicantInfo?: FetchEducationProfileResponse;
  productCode?: string;
}

/**
 * MBA applicants must go through a screening form first
 */
const EducationForm = (props: EducationFormProps) => {
  const {
    submitEventInProgress,
    submitEventLabel,
    submitEventInProgressLabel,
    parentFormHandler,
    existingApplicantInfo,
    productCode,
  } = props;

  const [isBusinessRelatedVisible] = useState(productCode !== "MSBA" && productCode !== "MSDT");
  const [areDegreeQuestionsVisible, setAreDegreeQuestionsVisible] = useState(false);
  const [isEducationLevelOtherVisible, setIsEducationLevelOtherVisible] = useState(false);
  const isNewInstitution = useRef(sessionStorage.getItem(IS_NEW_INSTITUTION) === "true").current;
  const [isTranscriptVisible] = useState(
    productCode === "MBA" || productCode === "MSDT" || productCode === "MSBA" || productCode === "MSE",
  );
  const [transcriptFile, setTranscriptFile] = useState<
    { originalFileName: string; uploadedFileName: string } | undefined
  >(
    existingApplicantInfo?.UnofficialTranscriptFileId
      ? {
          uploadedFileName: existingApplicantInfo.UnofficialTranscriptFileId || "",
          originalFileName: existingApplicantInfo.UnofficialTranscriptFileName || "",
        }
      : undefined,
  );

  const currentYear = new Date().getFullYear();
  const yearsList: Array<SelectItem> = Array.from({ length: 100 }, (_, index) => ({
    label: currentYear - index,
    value: currentYear - index,
  }));

  const existingEduLvl = () => {
    if (existingApplicantInfo?.HighestEducationLevel) {
      if (
        existingApplicantInfo.HighestEducationLevel !== BACHELORS_DEGREE &&
        existingApplicantInfo.HighestEducationLevel !== MASTERS_DEGREE &&
        existingApplicantInfo.HighestEducationLevel !== ASSOCIATE_DEGREE
      ) {
        return OTHER_DEGREE;
      }
      return existingApplicantInfo.HighestEducationLevel;
    }
    return "";
  };

  const businessRelatedVal = () =>
    (!isBusinessRelatedVisible && "NA") ||
    (existingApplicantInfo?.IsBusinessRelated && "Yes") ||
    (existingApplicantInfo?.IsBusinessRelated === false && "No") ||
    "";

  const educationLevelOtherVal = () =>
    (existingEduLvl() === OTHER_DEGREE && existingApplicantInfo?.HighestEducationLevel) || "";

  const degreeNameVal = () =>
    (existingEduLvl() !== OTHER_DEGREE && existingApplicantInfo?.DegreeOrCertificateName) || "";

  const graduationYearVal = () =>
    (existingApplicantInfo?.GraduationYear && existingApplicantInfo?.GraduationYear.toString()) || "";

  const newEducationForm = useForm({
    defaultValues: {
      ProductCode: productCode,
      EducationLevel: existingEduLvl(),
      EducationLevelOther: educationLevelOtherVal(),
      DegreeName: degreeNameVal(),
      GraduationYear: graduationYearVal(),
      InstitutionName: (existingApplicantInfo?.Accredited && existingApplicantInfo?.InstitutionName) || "",
      IsNewInstitution: isNewInstitution,
      InstitutionCountry: existingApplicantInfo?.InstitutionCountry,
      UnaccreditedInstitutionName: (!existingApplicantInfo?.Accredited && existingApplicantInfo?.InstitutionName) || "",
      BusinessRelated: businessRelatedVal(),
      UnofficialTranscriptFileId: existingApplicantInfo?.UnofficialTranscriptFileId || "",
      UnofficialTranscriptFileName: existingApplicantInfo?.UnofficialTranscriptFileName || "",
    },
    resolver: yupResolver(educationFormSchema),
  });
  const { control, handleSubmit, setValue } = newEducationForm;

  const {
    field: { value: EducationLevelValue, onChange: EducationLevelOnChange },
  } = useController({ name: "EducationLevel", control });
  const {
    field: { value: BusinessRelatedValue, onChange: BusinessRelatedOnChange },
  } = useController({ name: "BusinessRelated", control });
  const {
    field: { value: graduationYearValue, onChange: graduationYearOnChange },
  } = useController({ name: "GraduationYear", control });
  const {
    field: { value: institutionNameValue },
  } = useController({ name: "InstitutionName", control });
  const {
    field: { value: unaccreditedInstitutionNameValue },
  } = useController({ name: "UnaccreditedInstitutionName", control });

  const handleEducationFormSubmit: SubmitHandler<EducationFormSchemaType> = async (formValues) => {
    if (formValues.IsNewInstitution) {
      sessionStorage.setItem(IS_NEW_INSTITUTION, "true");
    } else {
      sessionStorage.removeItem(IS_NEW_INSTITUTION);
    }

    parentFormHandler(formValues);
  };

  useEffect(() => {
    if (existingApplicantInfo) {
      setAreDegreeQuestionsVisible(
        existingApplicantInfo.HighestEducationLevel === BACHELORS_DEGREE ||
          existingApplicantInfo.HighestEducationLevel === MASTERS_DEGREE,
      );
      setIsEducationLevelOtherVisible(
        !!existingApplicantInfo.HighestEducationLevel &&
          existingApplicantInfo.HighestEducationLevel !== BACHELORS_DEGREE &&
          existingApplicantInfo.HighestEducationLevel !== MASTERS_DEGREE &&
          existingApplicantInfo.HighestEducationLevel !== ASSOCIATE_DEGREE,
      );
    }
  }, [existingApplicantInfo]);

  useEffect(() => {
    if (!areDegreeQuestionsVisible) {
      setValue("BusinessRelated", "");
      setValue("DegreeName", "");
      setValue("GraduationYear", "");
    } else {
      setValue("BusinessRelated", businessRelatedVal());
      setValue("DegreeName", degreeNameVal());
      setValue("GraduationYear", graduationYearVal());
    }
  }, [areDegreeQuestionsVisible]);

  useEffect(() => {
    if (!isEducationLevelOtherVisible) {
      setValue("EducationLevelOther", "");
    } else {
      setValue("EducationLevelOther", educationLevelOtherVal());
    }
  }, [isEducationLevelOtherVisible]);

  useEffect(() => {
    setValue("UnofficialTranscriptFileId", transcriptFile?.uploadedFileName);
    setValue("UnofficialTranscriptFileName", transcriptFile?.originalFileName);
  }, [transcriptFile]);

  return (
    <FormProvider {...newEducationForm}>
      <form className="education-form" data-testid="education-form" onSubmit={handleSubmit(handleEducationFormSubmit)}>
        <Controller
          control={control}
          name="EducationLevel"
          render={({ field, fieldState }) => (
            <NxuSelect
              selectedValue={EducationLevelValue}
              onChange={(option) => {
                setAreDegreeQuestionsVisible(option.value === BACHELORS_DEGREE || option.value === MASTERS_DEGREE);
                setIsEducationLevelOtherVisible(option.value === OTHER_DEGREE);
                if (option.value !== OTHER_DEGREE) setValue("EducationLevelOther", "");
                EducationLevelOnChange(option.value);
              }}
              data-hj-allow
              options={EDUCATION_LEVEL_OPTIONS}
              field={field}
              fieldState={fieldState}
              placeholder={"What is your highest level of education?"}
              ariaLabel={"What is your highest level of education?"}
              isDisabled={submitEventInProgress}
            />
          )}
        />

        {isEducationLevelOtherVisible && (
          <Controller
            control={control}
            name="EducationLevelOther"
            render={({ field, fieldState }) => (
              <IonInput
                fill="outline"
                disabled={submitEventInProgress}
                onInput={field.onChange}
                onIonBlur={field.onBlur}
                type="text"
                placeholder="Fill in your education level"
                aria-label="Fill in your education level"
                errorText={fieldState.error?.message}
                className={fieldState.error ? "ion-touched ion-invalid" : ""}
                value={field.value}
              />
            )}
          />
        )}

        {areDegreeQuestionsVisible && (
          <>
            {isBusinessRelatedVisible && (
              <Controller
                control={control}
                name="BusinessRelated"
                render={({ field, fieldState }) => (
                  <NxuSelect
                    selectedValue={BusinessRelatedValue}
                    onChange={(option) => BusinessRelatedOnChange(option.value)}
                    options={BUSINESS_RELATED_OPTIONS}
                    field={field}
                    fieldState={fieldState}
                    placeholder={"Was your degree business related?"}
                    ariaLabel={"Was your degree business related?"}
                    isDisabled={submitEventInProgress}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="DegreeName"
              render={({ field, fieldState }) => (
                <IonInput
                  fill="outline"
                  disabled={submitEventInProgress}
                  onInput={field.onChange}
                  onIonBlur={field.onBlur}
                  type="text"
                  placeholder="Enter the name of your degree"
                  aria-label="Enter the name of your degree"
                  errorText={fieldState.error?.message}
                  className={fieldState.error ? "ion-touched ion-invalid" : ""}
                  value={field.value}
                />
              )}
            />
            <Controller
              control={control}
              name="GraduationYear"
              render={({ field, fieldState }) => (
                <NxuSelect
                  selectedValue={graduationYearValue}
                  onChange={(option) => graduationYearOnChange(option.value)}
                  options={yearsList}
                  field={field}
                  fieldState={fieldState}
                  placeholder={"Choose graduation year"}
                  ariaLabel={"Choose graduation year"}
                  isDisabled={submitEventInProgress}
                />
              )}
            />
          </>
        )}
        <UniversityInput
          isDisabled={submitEventInProgress}
          defaultValue={institutionNameValue || unaccreditedInstitutionNameValue || ""}
        />
        {isTranscriptVisible && (
          <EducationTranscriptUpload
            setUploadResponse={setTranscriptFile}
            inProgress={submitEventInProgress}
            existingOriginalFileName={existingApplicantInfo?.UnofficialTranscriptFileName || ""}
          />
        )}
        <NxuPrimaryButton type="submit" expand="block" disabled={submitEventInProgress}>
          {submitEventInProgress ? submitEventInProgressLabel : submitEventLabel}
        </NxuPrimaryButton>
      </form>
    </FormProvider>
  );
};

export default EducationForm;
