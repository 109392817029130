import React from "react";
import { IonNote } from "@ionic/react";
import { ControllerFieldState } from "react-hook-form";
import AsyncCreatableSelect from "react-select/async-creatable";
import cx from "classnames";
import { GroupBase, components } from "react-select";
import { useInstitutions } from "utils/hooks/institutions";
import { InputSpecificProps } from "react-select/dist/declarations/src/components/Input";

export interface InstitutionSelectItem {
  label: string;
  value: string;
  Country: string;
  // __isNew__ set by async-creatable
  __isNew__?: boolean;
}

export interface NxuInstitutionFieldProps {
  // react-select props
  fieldName: string;
  defaultValue: string;
  onChange: (...event: any[]) => void;
  placeholder: string;
  fieldState: ControllerFieldState;
  isDisabled?: boolean;
}

// https://github.com/JedWatson/react-select/issues/2810
const CustomInputContainer = (
  props: InputSpecificProps<InstitutionSelectItem, boolean, GroupBase<InstitutionSelectItem>>,
) => <components.Input {...props} maxLength={300} />;

/**
 * Institution name input field that will return the institution lookup from nexford api
 */
export const NxuInstitutionField = (props: NxuInstitutionFieldProps) => {
  const { fieldName, onChange, defaultValue, placeholder, fieldState, isDisabled } = props;
  const { mutateAsync } = useInstitutions();

  // Call the autocomplete and get the select options back
  const fetchInstitutionNameOptions = (inputValue: string, callback: (options: InstitutionSelectItem[]) => void) => {
    const trimmedInput = inputValue.trim().toUpperCase();
    if (trimmedInput) {
      mutateAsync(trimmedInput).then((data) => {
        let options: InstitutionSelectItem[] = [];
        if (data && data.value.length > 0) {
          options = data.value.map((d) => ({ label: d["@search.text"], value: d.rid, Country: d.Country }));
        }
        callback(options);
      });
    } else {
      callback([]);
    }
  };

  // On selecting an option, get the place details and return them to the parent
  const handleSelection = async (selectedOption?: InstitutionSelectItem) => {
    if (selectedOption) {
      onChange(selectedOption);
    } else {
      onChange(null);
    }
  };

  return (
    <div data-testid="nxu-institution-field">
      <AsyncCreatableSelect
        form={fieldName}
        loadOptions={fetchInstitutionNameOptions}
        cacheOptions
        // @ts-ignore
        onChange={(option) => handleSelection(option)}
        defaultInputValue={defaultValue}
        className={cx("nxu-select", fieldState.error && "nxu-select--error")}
        classNamePrefix="nxu-select"
        placeholder={placeholder}
        aria-label={placeholder}
        isDisabled={isDisabled}
        maxMenuHeight={180}
        menuShouldScrollIntoView
        isClearable
        backspaceRemovesValue
        blurInputOnSelect
        components={{
          Input: CustomInputContainer,
        }}
        allowCreateWhileLoading={true}
        noOptionsMessage={() => "Start typing to search..."}
        formatCreateLabel={(inputValue) => <span style={{ fontWeight: "bold" }}>Add "{inputValue}"</span>}
      />
      {fieldState.error && <IonNote className="nxu-select__error-note">{fieldState.error?.message}</IonNote>}
    </div>
  );
};

export default NxuInstitutionField;
