import React, { useState } from "react";
import EmailVerificationForm from "components/molecule/email-verification-form";
import CodeVerificationForm from "components/molecule/code-verification-form";
import { StorageKeys } from "constants/storage-keys";

import "./empty-verification-form.scss";
import { useLearnerPartnerAssociation } from "utils/hooks/authentication";
import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";

export interface EmptyVerificationFormProps {
  email?: string | null;
  token?: string | null;
  onTokenRetrieval?: (token: string, email: string) => void;
  onLearnerIdRetrieval?: (learnerId: string) => void;
  pageResetEvent?: () => void;
  partnerId?: string;
}

/**
 * Applicant has landed on ApplyNXU with no verification token and no product type & code
 * Check for an existing application and redirect them to the relevant page
 */
const EmptyVerificationForm = (props: EmptyVerificationFormProps) => {
  const {
    onTokenRetrieval,
    email,
    token,
    pageResetEvent: parentPageResetEvent,
    onLearnerIdRetrieval,
    partnerId,
  } = props;
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(!!email);
  const [emailToVerify, setEmailToVerify] = useState<string>(email || "");
  const [codeVerified, setCodeVerified] = useState<boolean>(!!token);
  const [learnerId, setLearnerId] = useState<string>(sessionStorage.getItem(StorageKeys.LEARNER_ID) || "");

  const {
    isLoading: isAssociationLoading,
    data: associationResponse,
    refetch,
  } = useLearnerPartnerAssociation(learnerId, partnerId, !!partnerId && emailSubmitted);

  const pageResetEvent = () => {
    // Reset the page state when applicant wants to edit their email
    setCodeVerified(false);
    sessionStorage.setItem(StorageKeys.APPLICATION_TOKEN, "");
    if (parentPageResetEvent) {
      parentPageResetEvent();
    }
  };

  const handleVerifyPartner = () => {
    refetch();
  };

  return (
    <div className="empty-verification-page__returning-applicant">
      <EmailVerificationForm
        setEmailToVerify={setEmailToVerify}
        setEmailSubmitted={setEmailSubmitted}
        storedEmail={emailToVerify}
        emailSubmitted={emailSubmitted}
        parentResetEvent={pageResetEvent}
        setLearnerId={(id) => {
          if (onLearnerIdRetrieval) {
            setLearnerId(id);
            onLearnerIdRetrieval(id);
          }
        }}
      />

      {isAssociationLoading && <NxuComponentLoading />}

      {emailSubmitted && !isAssociationLoading && (
        <CodeVerificationForm
          setCodeVerified={setCodeVerified}
          setTokenResponse={(t) => {
            if (onTokenRetrieval) {
              onTokenRetrieval(t, emailToVerify);
            }
          }}
          onVerifyPartner={handleVerifyPartner}
          partnerAssociated={associationResponse?.associated}
          storedEmail={emailToVerify}
          emailSubmitted={emailSubmitted}
          emailVerified={codeVerified}
          partnerId={partnerId}
        />
      )}
    </div>
  );
};

export default EmptyVerificationForm;
