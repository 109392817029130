import { NxuAlert, NxuContentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import GetInTouch from "components/atom/get-in-touch";
import ApplicantStatusBlock from "components/molecule/applicant-status-block/applicant-status-block";
import ApplicationErrorBlock from "components/molecule/application-error-block";
import ApplicationTitlePanel from "components/molecule/application-title-panel";
import Catalog from "components/molecule/catalog";
import EmptyVerificationForm from "components/molecule/empty-verification-form";
import { PageContent } from "components/molecule/page-wrap/page-wrap";
import ReturningApplicant from "components/molecule/returning-applicant";
import { NEXFORD_HOME_PROGRAMS } from "constants/external-routes";
import { LocalRoutes } from "constants/routes";
import { learningPathProducts } from "constants/products";
import { StorageKeys } from "constants/storage-keys";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ApplicationStatusOptions, Product } from "types/admissions";
import { FetchLearnerProfileResponse, SubmitLearnerProfileResponse } from "types/learner-profile";
import { ProductType } from "types/product";
import {
  AdmissionApplicationDataResponse,
  RegistrationData,
  RegistrationRequirement,
  RegistrationResponse,
  RegistrationStatus,
  RequirementStatus,
  RequirementType,
} from "types/registrations";
import { useProgramDetails } from "utils/hooks/admissions";
import { useLearnerProfileInfo } from "utils/hooks/learner-profile";
import { useRegistration, useStartRegistration } from "utils/hooks/registrations";
import ApplicationNavigation from "components/molecule/application-navigation";
import { usePartnerDetails } from "utils/hooks/partners";
import { PartnerDetails } from "types/partners";
import { getSearchParamsStr } from "utils/urlify";
import ProductsListModal from "components/molecule/products-list-modal";
import { useLearnerPartnerAssociation } from "utils/hooks/authentication";

export interface RegistrationContextType {
  loading: boolean;
  registrationData: RegistrationData | null;
  admissionRecord: AdmissionApplicationDataResponse | null;
  authorised: boolean;
  token: string | null;
  email: string | null;
  learnerId: string | null;
  programDetails?: Product;
  partnerDetails?: PartnerDetails;
  learnerProfileData?: FetchLearnerProfileResponse;
  CompleteRequirements: (
    submitRequirement: () => Promise<any>,
    requirements: RequirementType[],
    skipNavigation?: boolean,
    retainProcessingFlag?: boolean,
  ) => void;
  SetLearnerProfileData: (data: SubmitLearnerProfileResponse) => void;
  SwitchApplication: (newType: string, newCode: string) => void;
  RefreshRegistrationData: (newMatch: (_?: RegistrationResponse) => boolean) => void;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface RegistrationProviderProps {
  children?: React.ReactNode;
}

const sortedRequirements: RequirementType[] = [
  RequirementType.EducationInfo,
  RequirementType.PreviousDegreeLevel,
  RequirementType.UnofficialTranscript,
  RequirementType.PersonalInfo,
  RequirementType.EnglishProficiency,
  RequirementType.TermsAndConditions,
  RequirementType.PhotoIdentity,
  RequirementType.GovIdentity,
  RequirementType.CheckPartnerApplicationFeeAgreement,
  RequirementType.ApplicationFee,
  RequirementType.AdmissionDecision,
  RequirementType.CheckPartnerTuitionFeeAgreement,
  RequirementType.StartDate,
  RequirementType.Agreement,
  RequirementType.TuitionFee,
];

const routeMapping: { [requirement in RequirementType]: string | null } = {
  EducationInfo: LocalRoutes.APPLICATION_EDUCATION,
  UnofficialTranscript: LocalRoutes.APPLICATION_EDUCATION,
  PersonalInfo: LocalRoutes.APPLICATION,
  EnglishProficiency: LocalRoutes.APPLICATION,
  TermsAndConditions: LocalRoutes.APPLICATION,
  PhotoIdentity: LocalRoutes.APPLICATION_IDENTITY,
  GovIdentity: LocalRoutes.APPLICATION_IDENTITY,
  PreviousDegreeLevel: LocalRoutes.APPLICATION_EDUCATION,
  TuitionFee: LocalRoutes.TUITION_FEE_CHECKOUT,
  CheckPartnerApplicationFeeAgreement: LocalRoutes.CHECK_PARTNER_APPLICATION_FEE,
  ApplicationFee: LocalRoutes.APPLICATION_FEE_CHECKOUT,
  AdmissionDecision: LocalRoutes.APPLICATION_PENDING,
  Agreement: LocalRoutes.TUITION_FEE_CHECKOUT,
  CheckPartnerTuitionFeeAgreement: LocalRoutes.CHECK_PARTNER_TUITION_FEE,
  ConfirmPartnerTuitionPayment: LocalRoutes.TUITION_FEE_CHECKOUT,
  StartDate: LocalRoutes.TUITION_FEE_CHECKOUT,
};

const invalidAdmissionStatus = [
  ApplicationStatusOptions.ENROLLED,
  ApplicationStatusOptions.REJECTED,
  RegistrationStatus.Completed,
  RegistrationStatus.Rejected,
];
const routesWithEmailVerification: string[] = [LocalRoutes.HOME, LocalRoutes.APPLICATION_COMPLETE];
const routesWithoutCatalog: string[] = [LocalRoutes.APPLICATION_SUCCESS, LocalRoutes.APPLICATION_PENDING];
const degreeRoutesWithoutCatalog: string[] = [LocalRoutes.TUITION_FEE_CHECKOUT, ...routesWithoutCatalog];

const RegistrationContext = createContext<RegistrationContextType>({
  loading: false,
  authorised: false,
  token: null,
  email: null,
  learnerId: null,
  registrationData: null,
  admissionRecord: null,
  programDetails: undefined,
  partnerDetails: undefined,
  learnerProfileData: undefined,
  CompleteRequirements: () => undefined,
  SetLearnerProfileData: () => undefined,
  SwitchApplication: () => undefined,
  RefreshRegistrationData: () => undefined,
  setProcessing: () => undefined,
});

export function RegistrationProvider({ children }: RegistrationProviderProps) {
  const [registrationData, setRegistrationData] = useState<RegistrationData | null>(null);
  const [admissionRecord, setAdmissionRecord] = useState<AdmissionApplicationDataResponse | null>(null);
  const [invalidAdmissionRecord, setInvalidAdmissionRecord] = useState<boolean>(false);
  const [validRegistrationData, setValidRegistrationData] = useState<boolean>(false);
  const [missingPageError, setMissingPageError] = useState<boolean>(false);

  const [token, setToken] = useState(sessionStorage.getItem(StorageKeys.APPLICATION_TOKEN));
  const [email, setEmail] = useState(sessionStorage.getItem(StorageKeys.APPLICATION_EMAIL));
  const [learnerId, setLearnerId] = useState(sessionStorage.getItem(StorageKeys.LEARNER_ID));
  const [learnerProfileData, setLearnerProfileData] = useState<FetchLearnerProfileResponse>();

  const [redirectMatch, setRedirectMatch] = useState(() => (_?: RegistrationResponse) => true);
  const [processing, setProcessing] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const showEmailBlock = routesWithEmailVerification.includes(location.pathname);

  const productTypeSearchParam = searchParams.get("type");
  const productTypeSearchParamCapitalise = (productTypeSearchParam &&
    productTypeSearchParam[0].toUpperCase() + productTypeSearchParam.slice(1)) as ProductType;

  const productCodeSearchParam = searchParams.get("product");
  const partner = searchParams.get("partner");
  const productCodeSearchParamCapitalise = productCodeSearchParam && productCodeSearchParam.toUpperCase();

  const hasProductParams = productTypeSearchParamCapitalise && productCodeSearchParamCapitalise;

  const productType = productTypeSearchParamCapitalise || registrationData?.productType || admissionRecord?.productType;
  const productCode =
    productCodeSearchParamCapitalise || registrationData?.productCode || admissionRecord?.productCode || "";

  function supportsAdmissionContinuation(status: string) {
    return !invalidAdmissionStatus.find((x) => x === status);
  }

  const { data: associationResponse } = useLearnerPartnerAssociation(learnerId, partner, !!partner && !!learnerId);

  const canContinueOtherSteps = !!token && (partner ? !!associationResponse?.associated : true);
  const hasPartnerAndNotVerified = !!partner && !associationResponse?.associated;

  const {
    data: registrationApiData,
    error: registrationApiDataError,
    isSuccess: registrationDataSuccess,
    isLoading: registrationDataFetching,
  } = useRegistration(canContinueOtherSteps, token, productType, productCode, partner, redirectMatch);

  const {
    data: learnerProfileApiData,
    isSuccess: learnerProfileDataSuccess,
    isFetching: learnerProfileDataFetching,
  } = useLearnerProfileInfo(canContinueOtherSteps, token || "");

  const {
    data: programDetails,
    isLoading: programDetailsLoading,
    error: programDetailsError,
    isSuccess: programDetailsSuccess,
  } = useProgramDetails(productType, productCode);

  const {
    data: partnerDetails,
    error: partnerDetailsError,
    isLoading: partnerDetailsLoading,
  } = usePartnerDetails(true, { partnerId: partner });

  function SetLearnerProfileData(data: SubmitLearnerProfileResponse) {
    setLearnerProfileData({
      Email: data.email,
      FirstName: data.firstName,
      LastName: data.lastName,
      BirthYear: data.birthYear,
      PhoneCountryCode: data.phoneCountryCode,
      PhoneNumber: data.phoneNumber,
      City: data.city,
      State: data.state,
      Nationality: data.nationality,
      Country: data.country,
      Gender: data.gender,
      HowFoundNexford: data.howFoundNexford,
    });
  }

  function SetAuthenticationInfo(newToken: string, newEmail: string) {
    setToken(newToken);
    setEmail(newEmail);
  }

  function SortRequirements(requirements: RegistrationRequirement[]) {
    requirements.sort((a, b) => {
      if (sortedRequirements.indexOf(a.requirement) > sortedRequirements.indexOf(b.requirement)) {
        return 1;
      }

      return -1;
    });
  }

  function Reset() {
    setLearnerId(null);
    setEmail(null);
    setToken(null);
    setRegistrationData(null);
    setAdmissionRecord(null);
    sessionStorage.removeItem(StorageKeys.APPLICATION_EMAIL);
    sessionStorage.removeItem(StorageKeys.APPLICATION_TOKEN);
    sessionStorage.removeItem(StorageKeys.LEARNER_ID);
    sessionStorage.removeItem(StorageKeys.CURRENT_ROUTE);
  }

  function NavigateToNextPage(
    type: string,
    product: string,
    partnerId: string,
    requirements: RegistrationRequirement[],
  ) {
    if (requirements.every((r) => r.status === RequirementStatus.Fulfilled)) {
      navigate(LocalRoutes.APPLICATION_SUCCESS);
    } else {
      const nextRequirement = requirements.find((r) => r.status == RequirementStatus.Pending);

      if (nextRequirement) {
        const nextPage = routeMapping[nextRequirement.requirement];
        if (nextPage) {
          const search = getSearchParamsStr({
            type,
            product,
            partner: partnerId,
          });
          if (learningPathProducts.includes(product)) {
            if (
              location.pathname === LocalRoutes.APPLICATION_EDUCATION ||
              location.pathname === LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH
            ) {
              // Applicant is already on screening pages, ensure the URL has the product params, but allow the page to handle any further redirect
              navigate({
                pathname: location.pathname,
                search,
              });
            } else {
              navigate({
                pathname: nextPage,
                search,
              });
            }
          } else {
            navigate({
              pathname: nextPage,
              search,
            });
          }
        }
        // We have no route, display an error page
        else {
          setMissingPageError(true);
        }
      }
    }
  }

  const {
    isPending: startRegistrationPending,
    mutate: StartRegistration,
    error: startRegistrationError,
  } = useStartRegistration((resp) => {
    SortRequirements(resp.requirements);
    setRegistrationData(resp);
    if (resp) setValidRegistrationData(supportsAdmissionContinuation(resp.status));
    const existingSessionRoute = sessionStorage.getItem(StorageKeys.CURRENT_ROUTE);
    if (existingSessionRoute && existingSessionRoute.includes(resp.productCode)) {
      // There's an existing session and the product hasn't changed
      navigate(existingSessionRoute);
    } else {
      NavigateToNextPage(resp.productType, resp.productCode, resp.partnerId, resp.requirements);
    }
  });

  const isStartRegistrationPartnerNotSupportingProduct = startRegistrationError?.cause === 409;

  function RefreshRegistrationData(newMatch: (_?: RegistrationResponse) => boolean) {
    setRedirectMatch(() => newMatch);
  }

  function CompleteRequirements(
    submitRequirement: () => Promise<any>,
    requirements: RequirementType[],
    skipNavigation?: boolean,
    retainProcessingFlag?: boolean,
  ) {
    setProcessing(true);
    sessionStorage.removeItem(StorageKeys.CURRENT_ROUTE);
    submitRequirement()
      .then(() => {
        if (registrationData) {
          const updatedRequirements = registrationData.requirements?.map((r) =>
            requirements.findIndex((rr) => rr == r.requirement) > -1
              ? { ...r, status: RequirementStatus.Fulfilled }
              : r,
          );

          setRegistrationData({
            ...registrationData,
            requirements: updatedRequirements,
          });
          if (!skipNavigation) {
            NavigateToNextPage(
              registrationData.productType,
              registrationData.productCode,
              registrationData.partnerId,
              updatedRequirements,
            );
          }
        }
      })
      .finally(() => {
        if (!retainProcessingFlag) setProcessing(false);
      });
  }

  const SwitchApplication = useCallback(
    (newType: string, newCode: string) => {
      sessionStorage.removeItem(StorageKeys.CURRENT_ROUTE);
      StartRegistration({ productType: newType, productCode: newCode, partnerId: partner });
    },
    [StartRegistration, partner],
  );

  useEffect(() => {
    if (registrationApiData?.registrationDto) {
      const { partnerId } = registrationApiData.registrationDto || {};

      const params = new URLSearchParams(searchParams);
      if (partnerId) {
        params.set("partner", partnerId);
      }

      setSearchParams(params);
    }
  }, [registrationApiData, searchParams, setSearchParams]);

  // Identify next step after get Registration request is complete
  useEffect(() => {
    if (registrationDataSuccess && !programDetailsLoading) {
      const { registrationDto, applicationDto } = registrationApiData;

      if (registrationDto?.status === RegistrationStatus.Completed) {
        SortRequirements(registrationDto.requirements);
        setRegistrationData(registrationDto);

        if (
          location.pathname !== LocalRoutes.APPLICATION_SUCCESS &&
          location.pathname !== LocalRoutes.APPLICATION_COMPLETE
        ) {
          navigate(LocalRoutes.APPLICATION_COMPLETE);
        }
        return;
      }

      const stateIsStale =
        !registrationData ||
        registrationData?.productCode !== registrationApiData.registrationDto?.productCode ||
        partner != registrationApiData.registrationDto?.partnerId;
      const admissionOk = !applicationDto || !invalidAdmissionRecord;

      if (stateIsStale && !!programDetails && admissionOk && !registrationData) {
        StartRegistration({
          productType: registrationDto?.productType || programDetails?.ProductType || "",
          productCode: registrationDto?.productCode || programDetails?.ProductCode || "",
          partnerId: registrationDto?.partnerId || partner,
        });
      } else if (registrationData && registrationApiData.registrationDto) {
        SortRequirements(registrationApiData.registrationDto.requirements);
        setRegistrationData(registrationApiData.registrationDto);
      }
    }
  }, [
    // Note: Don't allow the dependency array to reference "navigate" or "NavigateToNextPage", it will trigger a memory leak on the useEffect
    StartRegistration,
    hasProductParams,
    programDetails,
    invalidAdmissionRecord,
    programDetailsLoading,
    programDetailsSuccess,
    registrationApiData,
    registrationDataFetching,
    registrationDataSuccess,
    partner,
  ]);

  // When admission data successfully loads, set the data to the context state
  useEffect(() => {
    if (registrationDataSuccess) {
      const { registrationDto, applicationDto } = registrationApiData;
      if (registrationDto) setValidRegistrationData(supportsAdmissionContinuation(registrationDto.status));

      if (applicationDto) {
        setAdmissionRecord(applicationDto);
        if (registrationDto && registrationDto.requirements.find((r) => r.status === RequirementStatus.Fulfilled)) {
          // Registration already has a fulfilled req, can ignore the applicationDto check
          return;
        }

        setInvalidAdmissionRecord(!supportsAdmissionContinuation(applicationDto.status));
      }
    }
  }, [registrationApiData, registrationDataSuccess]);

  // When learner profile successfully loads, set the data to the context state
  useEffect(() => {
    if (learnerProfileDataSuccess) setLearnerProfileData(learnerProfileApiData);
  }, [learnerProfileApiData, learnerProfileDataSuccess]);

  const loading =
    registrationDataFetching ||
    startRegistrationPending ||
    processing ||
    programDetailsLoading ||
    learnerProfileDataFetching ||
    partnerDetailsLoading;

  const hasActiveRegistration = !!registrationData?.status;
  const hasActiveAdmission = !!admissionRecord?.status && admissionRecord?.status !== "Expired";

  const contextValues = {
    loading,
    authorised: !!token,
    token,
    email,
    learnerId,
    registrationData,
    admissionRecord,
    programDetails,
    partnerDetails,
    learnerProfileData,
    CompleteRequirements,
    SetLearnerProfileData,
    SwitchApplication,
    setProcessing,
    RefreshRegistrationData,
  };

  const showChangeProgramModal = useMemo(
    () => isStartRegistrationPartnerNotSupportingProduct && !startRegistrationPending,
    [startRegistrationPending, isStartRegistrationPartnerNotSupportingProduct],
  );

  if (registrationApiDataError || missingPageError) {
    return (
      <PageContent className="application-page">
        <ApplicationErrorBlock />
      </PageContent>
    );
  }

  if (showChangeProgramModal) {
    return (
      <PageContent className="application-page">
        <ProductsListModal
          isOpen={showChangeProgramModal}
          closeModal={() => {}}
          partnerId={partner ?? undefined}
          refetchOnOpen={true}
          renderAlert={
            <NxuAlert
              type="warning"
              message={`The program you have selected is not supported by your Nexford partner: ${partnerDetails?.name}. Please select a different program.`}
            />
          }
          onSwitchProgram={SwitchApplication}
          isPersistent
        />
      </PageContent>
    );
  }

  if (loading) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1 />
          {hasProductParams && !programDetailsLoading && !!programDetails && (
            <ApplicationTitlePanel
              productType={programDetails.ProductType}
              productCode={programDetails.ProductCode}
              partnerId={partner ?? undefined}
              friendlyName={programDetails.FriendlyName}
              productProvider={programDetails.ProductProvider}
              hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
            />
          )}
          <NxuContentLoading mode="dark" />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  if (programDetailsError || (programDetailsSuccess && !programDetails)) {
    return (
      <PageContent className="application-page">
        <NxuAlert
          type="warning"
          message={
            programDetailsError
              ? programDetailsError.data.details
              : "We can't find a matching program as the product code or type are missing from the URL"
          }
        />
        <GetInTouch>
          <p>
            <a href={NEXFORD_HOME_PROGRAMS}>Head over to our catalog to select another program</a>
          </p>
          <p>Any questions? Get in touch and we’ll help.</p>
        </GetInTouch>
      </PageContent>
    );
  }

  if (partnerDetailsError) {
    return (
      <PageContent className="application-page">
        <NxuAlert type="warning" message={partnerDetailsError.data.details} />
      </PageContent>
    );
  }

  if (!token || hasPartnerAndNotVerified || (!hasActiveRegistration && !hasActiveAdmission)) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1>Welcome to Nexford ApplyNXU</h1>
          {hasProductParams && (
            <ApplicationTitlePanel
              productType={programDetails!.ProductType}
              productCode={programDetails!.ProductCode}
              partnerId={partner ?? undefined}
              friendlyName={programDetails!.FriendlyName}
              productProvider={programDetails!.ProductProvider}
              hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
            />
          )}
          <CardPanel>
            {!hasProductParams && <ReturningApplicant showAlert={!!token && !hasActiveRegistration} />}
            <EmptyVerificationForm
              onTokenRetrieval={SetAuthenticationInfo}
              pageResetEvent={Reset}
              onLearnerIdRetrieval={(id) => setLearnerId(id)}
              email={email}
              token={token}
              partnerId={partner ?? undefined}
            />
            {!hasProductParams && <Catalog partnerId={partner ?? undefined} />}
          </CardPanel>
          <GetInTouch />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  if (registrationData && !validRegistrationData) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1 />
          <ApplicationTitlePanel
            productType={programDetails!.ProductType}
            productCode={programDetails!.ProductCode}
            partnerId={partner ?? undefined}
            friendlyName={programDetails!.FriendlyName}
            productProvider={programDetails!.ProductProvider}
            hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
          />
          <ApplicantStatusBlock admissionStatus={registrationData.status} />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  if (admissionRecord && invalidAdmissionRecord) {
    return (
      <RegistrationContext.Provider value={contextValues}>
        <PageContent className="application-page">
          <h1 />
          <ApplicationTitlePanel
            productType={programDetails!.ProductType}
            productCode={programDetails!.ProductCode}
            partnerId={partner ?? undefined}
            friendlyName={programDetails!.FriendlyName}
            productProvider={programDetails!.ProductProvider}
            hideCatalogLink={routesWithoutCatalog.includes(location.pathname)}
          />
          <ApplicantStatusBlock admissionStatus={admissionRecord.status} />
        </PageContent>
      </RegistrationContext.Provider>
    );
  }

  return (
    <RegistrationContext.Provider value={contextValues}>
      <PageContent className="application-page">
        <h1 />
        <ApplicationTitlePanel
          productType={programDetails!.ProductType}
          productCode={programDetails!.ProductCode}
          partnerId={partner ?? undefined}
          friendlyName={programDetails!.FriendlyName}
          productProvider={programDetails!.ProductProvider}
          hideCatalogLink={
            programDetails!.ProductType === "Degree" || programDetails!.ProductType === "Certificate"
              ? degreeRoutesWithoutCatalog.includes(location.pathname)
              : routesWithoutCatalog.includes(location.pathname)
          }
          onSwitchProgram={canContinueOtherSteps ? SwitchApplication : undefined}
        />
        <ApplicationNavigation
          productType={programDetails!.ProductType}
          productCode={programDetails!.ProductCode}
          partnerId={partner ?? undefined}
          registrationData={registrationData}
        />
        {showEmailBlock && (
          <CardPanel>
            <EmptyVerificationForm
              onTokenRetrieval={SetAuthenticationInfo}
              pageResetEvent={Reset}
              email={email}
              token={token}
              partnerId={partner ?? undefined}
            />
          </CardPanel>
        )}
        {children}
      </PageContent>
    </RegistrationContext.Provider>
  );
}

export const useRegistrationContext = () => useContext(RegistrationContext);
