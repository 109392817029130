import { InstitutionsResponse } from "types/institutions";
import { useMutation } from "@tanstack/react-query";
import { apiGet } from "./api-client";

const fetchInstitutions = async (searchTerm: string): Promise<InstitutionsResponse> =>
  apiGet(`/api/institutions-search/suggest?search=${searchTerm}&$select=Country,rid`, "Error fetching institutions");

const useInstitutions = () =>
  useMutation({
    mutationFn: (searchTerm: string) => fetchInstitutions(searchTerm),
  });

export { useInstitutions };
