import NxuInstitutionField from "components/atom/nxu-institution-field";
import {
  InstitutionSelectItem,
  NxuInstitutionFieldProps,
} from "components/atom/nxu-institution-field/nxu-institution-field";
import { Controller, useFormContext } from "react-hook-form";
import { SelectItem } from "types/forms";
import { CountrySelect } from "./country-select";
import { EducationFormSchemaType } from "../schema";

type Props = {} & Pick<NxuInstitutionFieldProps, "defaultValue" | "isDisabled">;

export const UniversityInput = ({ isDisabled, defaultValue }: Props) => {
  const { clearErrors, setValue, control, watch } = useFormContext<EducationFormSchemaType>();

  const isNewInstitution = watch("IsNewInstitution");

  const institutionToPayload = (selectedInstitution?: InstitutionSelectItem) => {
    clearErrors("InstitutionSelect");

    if (selectedInstitution) {
      // eslint-disable-next-line no-underscore-dangle
      if (selectedInstitution.__isNew__) {
        setValue("InstitutionName", "");
        setValue("UnaccreditedInstitutionName", selectedInstitution.value);
        setValue("IsNewInstitution", true);
        setValue("InstitutionCountry", "");
      } else {
        // We want to store the Institution Name from the label, as we don't make use of the ID value in our other systems
        setValue("InstitutionName", selectedInstitution.label);
        setValue("UnaccreditedInstitutionName", "");
        setValue("IsNewInstitution", false);
        setValue("InstitutionCountry", selectedInstitution.Country);
      }
    } else {
      setValue("InstitutionName", "");
      setValue("UnaccreditedInstitutionName", "");
      setValue("IsNewInstitution", false);
      setValue("InstitutionCountry", "");
    }
  };

  return (
    <>
      <Controller
        control={control}
        name="InstitutionSelect"
        render={({ fieldState, field }) => (
          <NxuInstitutionField
            fieldName="InstitutionSelect"
            onChange={(option) => institutionToPayload(option)}
            placeholder={"Select the university/college you graduated from"}
            fieldState={fieldState}
            defaultValue={defaultValue}
            isDisabled={field.disabled}
          />
        )}
        disabled={isDisabled}
      />
      {isNewInstitution && (
        <Controller
          name="InstitutionCountry"
          control={control}
          render={({ fieldState, field }) => (
            <CountrySelect
              testId="nxu-institution-country"
              selectedValue={field.value}
              onChange={({ value }: SelectItem) => field.onChange(value)}
              field={field}
              fieldState={fieldState}
              placeholder="Institution Country"
              isDisabled={field.disabled}
            />
          )}
          disabled={isDisabled}
        />
      )}
    </>
  );
};
